import {
  BoxWrapper,
  Flex,
  PositionRelative,
  Width800Wrapper,
} from '../stylesComponents/Tags'
import LoaderTransparent from '../common/LoaderTransparent'
import FieldValueBase from '../fieldValue/FieldValueBase'
import Button from '@material-ui/core/Button'
import { UpsertWrapperWithScroll } from '../stylesComponents/UpsertCommon'
import { useState } from 'react'
import {
  getUpsertOnboardingImportDefaultFieldValues,
  getUpsertOnboardingImportFieldValuesValidated,
} from '../../context/UpsertMigrationFieldValues'
import { ButtonWrapper } from '../stylesComponents/Buttons'
import toastrService from '../../service/toastrService'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import { getRequestStatusDefault } from '../../context/GlobalContext'
import { importCapitalProvider } from '../../service/onboardingApiService'
import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export default () => {
  const [
    upsertOnboardingImportRequest,
    setUpsertOnboardingImportRequest,
  ] = useState(getRequestStatusDefault())
  const [
    upsertOnboardingImportFieldValues,
    setUpsertOnboardingImportFieldValues,
  ] = useState(getUpsertOnboardingImportDefaultFieldValues())

  const [importedUser, setImportedUser] = useState(null)

  const onSubmitImportOnboardingUserWithErrorHandling = async () => {
    try {
      await onSubmitImportOnboardingUser()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
    }
  }

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...upsertOnboardingImportFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }
    setUpsertOnboardingImportFieldValues(fieldValuesClone)
  }

  const onSubmitImportOnboardingUser = async () => {
    const {
      fieldValuesValidated,
      isValid,
    } = getUpsertOnboardingImportFieldValuesValidated(
      upsertOnboardingImportFieldValues
    )

    if (!isValid) {
      setUpsertOnboardingImportRequest({
        ...upsertOnboardingImportRequest,
        submitRequestStatus: RequestStatusEnum.error,
      })
      setUpsertOnboardingImportFieldValues(fieldValuesValidated)
      return
    }

    setUpsertOnboardingImportRequest({
      ...upsertOnboardingImportRequest,
      submitRequestStatus: RequestStatusEnum.loading,
    })
    console.log({
      walletAddress: upsertOnboardingImportFieldValues.walletAddress.value,
      kycId: upsertOnboardingImportFieldValues.kycId.value,
    })

    const user = await importCapitalProvider({
      walletAddress: upsertOnboardingImportFieldValues.walletAddress.value,
      kycId: upsertOnboardingImportFieldValues.kycId.value,
    })
    console.log('Imported user: ', user)
    setImportedUser(user)

    setUpsertOnboardingImportRequest({
      ...upsertOnboardingImportRequest,
      submitRequestStatus: RequestStatusEnum.success,
    })
  }

  return (
    <UpsertWrapperWithScroll>
      <PositionRelative>
        <LoaderTransparent active={false}>
          <BoxWrapper>
            <h4>Migrate existing capital provider</h4>
            <br />

            <Width800Wrapper>
              <Flex>
                <FieldValueBase
                  value={upsertOnboardingImportFieldValues.walletAddress.value}
                  name="walletAddress"
                  label="Wallet address"
                  withError
                  error={upsertOnboardingImportFieldValues.walletAddress.error}
                  onFieldUpdate={onFieldUpdate}
                />
                &nbsp;&nbsp;&nbsp;
                <FieldValueBase
                  value={upsertOnboardingImportFieldValues.kycId.value}
                  name="kycId"
                  label="KYC/KYB ID"
                  withError
                  error={upsertOnboardingImportFieldValues.kycId.error}
                  onFieldUpdate={onFieldUpdate}
                />
                <ButtonWrapper>
                  <Button
                    variant="outlined"
                    size="medium"
                    color="primary"
                    onClick={onSubmitImportOnboardingUserWithErrorHandling}
                  >
                    Import
                  </Button>
                </ButtonWrapper>
              </Flex>

              <br />
              <Content>
                {importedUser ? (
                  <ul>
                    {Object.entries(importedUser).map(( [userKey, userValue], index) => (
                    <li key={userKey}>
                      {userKey}: {userValue}
                    </li>
                    ))}
                  </ul>
                ) : null}
              </Content>
            </Width800Wrapper>
          </BoxWrapper>
        </LoaderTransparent>
      </PositionRelative>
    </UpsertWrapperWithScroll>
  )
}
